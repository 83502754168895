import { render, staticRenderFns } from "./EnableDisableAdmin.vue?vue&type=template&id=3c0aa0c3&scoped=true"
import script from "./EnableDisableAdmin.vue?vue&type=script&lang=js"
export * from "./EnableDisableAdmin.vue?vue&type=script&lang=js"
import style0 from "./EnableDisableAdmin.vue?vue&type=style&index=0&id=3c0aa0c3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c0aa0c3",
  null
  
)

export default component.exports