<template>
  <b-row
    class="d-flex flex-row align-items-start justify-content-start"
    no-gutters
  >
    <b-col class="d-flex flex-column align-items-start justify-content-start">
      <h6
        class="text-left text-dark font-weight-semi-bold font-primary mb-4 mt-3"
      >
        Add a new administrator
      </h6>
      <!-- Add a New Admin form -->
      <form
        id="admin-registration-form"
        class="d-flex flex-column align-items-start justify-content-start w-100"
      >
        <!-- full row input (Name) -->
        <b-row
          class="d-flex flex-row align-items-start justify-content-start w-100"
          no-gutters
        >
          <b-col cols="12">
            <FormInput
              groupId="admin-name-group"
              id="admin-name-group-input"
              class="text-prime-gray"
              :state="firstNameError.status"
              :invalidFeedback="`${firstNameError.message}`"
              label="First Name"
              v-model="firstName"
              isRequired
              trim
              form="admin-registration-form"
              type="text"
            ></FormInput>
          </b-col>
        </b-row>
        <b-row
          class="d-flex flex-row align-items-start justify-content-start w-100"
          no-gutters
        >
          <b-col cols="12">
            <FormInput
              groupId="admin-name-group"
              id="admin-name-group-input"
              class="text-prime-gray"
              :state="lastNameError.status"
              :invalidFeedback="`${lastNameError.message}`"
              label="Last Name"
              v-model="lastName"
              isRequired
              trim
              form="admin-registration-form"
              type="text"
            ></FormInput>
          </b-col>
        </b-row>
        <!-- full row input (Email) -->
        <b-row
          class="d-flex flex-row align-items-start justify-content-start w-100"
          no-gutters
        >
          <b-col cols="12">
            <FormInput
              groupId="admin-email-group"
              id="admin-email-group-input"
              class="text-prime-gray"
              :state="emailError.status"
              :invalidFeedback="`${emailError.message}`"
              label="Email Address"
              v-model="email"
              placeholder="user@example.com"
              isRequired
              trim
              form="admin-registration-form"
              type="text"
            ></FormInput>
          </b-col>
        </b-row>
        <!-- full row input (Admin Type) -->
        <!-- <b-row
          class="d-flex flex-row align-items-start justify-content-start w-100"
          no-gutters
        >
          <b-col cols="12">
            <FormSelect
              groupId="admin-type-group"
              class="w-100"
              id="admin-type-group-select"
              label="Admin Type"
              :state="adminTypeError.status"
              :invalidFeedback="`${adminTypeError.message}`"
              v-model="adminType"
              :options="AdminTypeOptions"
              form="admin-registration-form"
            ></FormSelect>
          </b-col>
        </b-row> -->
        <!-- full row input (Password) -->
        <b-row
          class="d-flex flex-row align-items-start justify-content-start w-100"
          no-gutters
        >
          <b-col cols="12">
            <FormInputGroup
              groupId="password-group"
              id="password-group-input"
              v-model="password"
              :state="passwordError.status"
              :invalidFeedback="`${passwordError.message}`"
              label="Password"
              placeholder="Minimum 6 characters"
              isRequired
              :type="showPassword ? 'text' : 'password'"
              form="admin-registration-form"
              isAppend
            >
              <template v-slot:append>
                <b-button
                  class="px-3 px-md-4"
                  variant="white"
                  @click="showPassword = !showPassword"
                >
                  <font-awesome-icon
                    :icon="showPassword ? 'eye-slash' : 'eye'"
                    aria-label="Help"
                  ></font-awesome-icon>
                </b-button>
              </template>
            </FormInputGroup>
          </b-col>
        </b-row>

        <!-- <template v-if="adminType == 'Country Manager'">
                <b-row class="d-flex flex-row align-items-start justify-content-start w-100" no-gutters>
                    <b-col cols="12" class="px-0">
                        <b-form-group id="region-selector-group" label="Country/Language" :class="regionSelectorFocus ? 'is-focus' : ''" label-class="region-selector-label mb-0 py-0" description="You can assign multiple countries to each Country Manager." :invalid-feedback="regionSelectorError.message" :state="regionSelectorError.status">
                            <b-form-tags v-model="regionSelector" add-on-change no-outer-focus class="region-selector-form-tags shadow-none border-0 px-0 pt-0 pb-2">
                                <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                                    <b-form-select v-bind="inputAttrs" v-on="inputHandlers" :disabled="disabled || availableOptions.length === 0" :options="availableOptions" :state="regionSelectorError.status" :invalid-feedback="`${regionSelectorError.message}`" isRequired v-model="regionSelector[tags]" class="region-selector-select rounded-0" @blur.native="regionSelectorFocus = false" @focus.native="regionSelectorFocus = true">
                                        <template v-slot:first>
                                            This is required to prevent bugs with Safari
                                            <option disabled value>Choose a Country/Language</option>
                                        </template>
                                    </b-form-select>
                                    <ul v-if="tags.length > 0" class="list-inline d-inline-block mt-3 mb-2">
                                        <li v-for="tag in tags" :key="tag" class="list-inline-item">
                                            <b-form-tag @remove="removeTag(tag)" :title="tag" :disabled="disabled" variant="secondary" class="font-weight-normal align-items-center rounded-0 px-2 py-2 my-1 mr-1">{{ tag }}</b-form-tag>
                                        </li>
                                    </ul>
                                </template>
                            </b-form-tags>
                        </b-form-group>
                    </b-col>
                </b-row>
            </template> -->
        <!-- form action -->
        <b-row
          v-if="createAdminError.status == false"
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-center
            w-100
          "
          no-gutters
        >
          <!-- error message span class -->
          <b-col
            class="
              d-flex
              flex-column
              align-items-center
              justify-content-center
              px-0
              py-2
            "
            cols="9"
            sm="6"
          >
            <span class="text-danger" style="font-size: 0.7rem">{{
              createAdminError.message
            }}</span>
          </b-col>
        </b-row>
        <!-- login button -->
        <b-row
          class="
            d-flex
            flex-row
            align-items-center
            justify-content-center
            w-100
          "
          no-gutters
        >
          <b-col
            class="
              d-flex
              flex-column
              align-items-center
              justify-content-center
              px-0
              py-2
            "
            cols="9"
            sm="6"
          >
            <FormButton
              variant="primary"
              isBlock
              class="font-weight-normal text-white mt-2 border-dark"
              type="submit"
              @click.native="adminCreateFn"
              :isLoading="isLoading"
              >Create Admin</FormButton
            >
          </b-col>
        </b-row>
      </form>
    </b-col>
  </b-row>
</template>

<script>
import FormInput from "@/components/Form/FormInput";
import FormInputGroup from "@/components/Form/FormInputGroup";
// import FormSelect from "@/components/Form/FormSelect";
import FormButton from "@/components/Form/FormButton";
import CheckEmail from "@/util/CheckEmail";

// services
import { CreateAdmin } from "../../services/admin.service";

export default {
  name: "CreateAdmin",
  components: {
    FormInput,
    FormInputGroup,
    // FormSelect,
    FormButton,
  },
  data() {
    return {
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      adminType: null,
      // AdminTypeOptions: ["Master Admin", "Super Admin", "General Admin"],
      AdminTypeOptions: ["Administrator", "Master Administrator"],
      showPassword: false,
      isLoading: false,
      firstNameError: {
        status: null,
        message: "",
      },
      lastNameError: {
        status: null,
        message: "",
      },
      emailError: {
        status: null,
        message: "",
      },
      adminTypeError: {
        status: null,
        message: "",
      },
      passwordError: {
        status: null,
        message: "",
      },
      createAdminError: {
        status: false,
        message: "",
      },
      isReset: false,
    };
  },
  watch: {
    firstName(val) {
      if (!this.isReset) {
        if (val) {
          this.firstNameError.status = true;
        } else {
          this.firstNameError.status = false;
          this.firstNameError.message = "Please fill in the name";
        }
      }
    },
    lastName(val) {
      if (!this.isReset) {
        if (val) {
          this.lastNameError.status = true;
        } else {
          this.lastNameError.status = false;
          this.lastNameError.message = "Please fill in the name";
        }
      }
    },
    email(val) {
      if (!this.isReset) {
        if (val) {
          if (CheckEmail(val) == true) this.emailError.status = true;
          else {
            this.emailError.status = false;
            this.emailError.message = "Please fill in a valid email address";
          }
        } else {
          this.emailError.status = false;
          this.emailError.message = "Please fill in the email address";
        }
      }
    },
    adminType(val) {
      if (!this.isReset) {
        if (!val) {
          this.adminTypeError.status = false;
          this.adminTypeError.message = "Please select the admin type";
        } else if (!this.AdminTypeOptions.includes(val)) {
          this.adminTypeError.status = false;
          this.adminTypeError.message = "Selected admin type is invalid";
        } else this.adminTypeError.status = true;
      }
    },
    password(val) {
      if (!this.isReset) {
        if (val) {
          if (val.length < 6) {
            this.passwordError.status = false;
            this.passwordError.message =
              "A minimum length of 6 characters needed";
          } else {
            this.passwordError.status = true;
          }
        } else {
          this.passwordError.status = false;
          this.passwordError.message = "Please fill in the password";
        }
      }
    },
  },
  computed: {
    computedAdminType() {
      if (this.adminType == "Master Administrator") {
        return "MASTER";
      } else {
        return "ADMIN";
      }
    },
  },
  methods: {
    async adminCreateFn() {
      let errorList = this.errorFn();
      if (errorList.length == 0) {
        let payload = {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          type: this.computedAdminType,
          password: this.password,
        };

        this.isLoading = true;
        try {
          let { data } = await CreateAdmin(payload);
          if (data.code == 200 && data.message == "success") {
            // this.$store.dispatch("register/setVendor", res.data.content);
            let payloadNotify = {
              isToast: true,
              title: "New Admin Creation",
              content: `A new Admin has been created Successfully`,
              variant: "success",
            };

            this.$store.dispatch("notification/setNotify", payloadNotify);
            // location.reload();
            this.resetFn();
            setTimeout(() => this.$emit("reloadFn"), 600);
          } else {
            // server validation error
            this.createAdminError.status = false;
            this.createAdminError.message = data.details;

            // display error messages in the span class
            setTimeout(() => {
              this.createAdminError.status = null;
              this.createAdminError.message = "";
            }, 3000);

            // display email error message in the form field
            let payloadNotify = {
              isToast: true,
              title: "New Admin Creation",
              content: "Administrator account cannot be created.",
              list: [data.details],
              variant: "danger",
            };

            this.$store.dispatch("notification/setNotify", payloadNotify);
          }
        } catch (error) {
          // request error
          // this.isLoader = false;
          this.createAdminError.status = false;
          this.createAdminError.message = "User with same email already exists";

          // display error messages in the span class
          setTimeout(() => {
            this.createAdminError.status = null;
            this.createAdminError.message = "";
          }, 3000);

          let payloadNotify = {
            isToast: true,
            title: "New Admin Creation",
            content: "User with same email already exists.",
            // list: [error.message],
            variant: "danger",
          };
          this.$store.dispatch("notification/setNotify", payloadNotify);
        } finally {
          this.isLoading = false;
        }
      } else {
        // form validatation error
        let payloadNotify = {
          isToast: true,
          title: "New Admin Creation",
          content: "The Admin cannot be created. Details: ",
          list: errorList,
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
    },
    errorFn() {
      var errorList = [];
      // validate name
      if (!this.firstName) {
        this.firstNameError.status = false;
        this.firstNameError.message = "Please fill in the name";
        errorList.push("Please fill in the name");
      }
      if (!this.lastName) {
        this.lastNameError.status = false;
        this.lastNameError.message = "Please fill in the name";
        errorList.push("Please fill in the name");
      }
      // validate email
      if (!this.email) {
        this.emailError.status = false;
        this.emailError.message = "Please fill in the email address";
        errorList.push("Please fill in the email address");
      } else if (!CheckEmail(this.email)) {
        this.emailError.status = false;
        this.emailError.message = "Email address is invalid";
        errorList.push("Email address is invalid");
      }
      // validate password
      if (!this.password) {
        this.passwordError.status = false;
        this.passwordError.message = "Please fill in the password";
        errorList.push("Please fill in the password");
      } else if (
        (!this.password && this.password.length < 6) ||
        (this.password &&
          this.password.length > 0 &&
          this.password.length < 6)
      ) {
        this.passwordError.status = false;
        this.passwordError.message = "Minimum length of 6 characters";
        errorList.push("Password length must have at least 6 characters");
      }
      // validate admin type
      // if (!this.adminType) {
      //   this.adminTypeError.status = false;
      //   this.adminTypeError.message = "Please select the admin type";
      //   errorList.push("Please select the admin type");
      // } else if (!this.AdminTypeOptions.includes(this.adminType)) {
      //   this.adminTypeError.status = false;
      //   this.adminTypeError.message = "Selected admin type is invalid";
      //   errorList.push("Please select a valid admin type");
      // }
      return errorList;
    },
    resetFn() {
      this.isReset = true;
      this.firstName = null;
      this.lastName = null;
      this.email = null;
      this.password = null;
      this.adminType = null;
      this.firstNameError.status = null;
      this.lastNameError.status = null;
      this.emailError.status = null;
      this.passwordError.status = null;
      this.adminTypeError.status = null;
      setTimeout(() => (this.isReset = false), 300);
    },
  },
};
</script>

<style scoped>
/* == FORM GROUP STYLING == */
/* customized form control */
#region-selector-group.form-group {
  position: relative;
}

#region-selector-group.form-group
  >>> select.region-selector-select::placeholder {
  color: var(--prime-gray);
}

/* == FORM SELECT STYLING == */
/* form custom select styling (default state) */
#region-selector-group.form-group >>> select.region-selector-select {
  min-height: 50px;
  border-radius: 0;
  background-color: transparent;
  color: var(--muted-gray);
}

#region-selector-group >>> select.region-selector-select option {
  color: #4f5467;
}

/* form custom select error state styling (valid state) */
#region-selector-group.form-group.is-valid >>> select.region-selector-select {
  border-color: var(--success);
}

/* form custom select error state styling (invalid state) */
#region-selector-group.form-group.is-invalid >>> select.region-selector-select {
  border-color: var(--danger);
}

/* form custom select focused styling (focus state) */
#region-selector-group.form-group.is-focus >>> select.region-selector-select {
  z-index: 0;
}

#region-selector-group.form-group >>> select.region-selector-select:focus {
  border-color: var(--info);
  box-shadow: none;
}

/* form custom select focused error state styling (focus/valid state) */
#region-selector-group.form-group.is-focus.is-valid
  >>> select.region-selector-select {
  border-color: var(--success);
}

/* form custom select focused error state styling (focus/invalid state) */
#region-selector-group.form-group.is-focus.is-invalid
  >>> select.region-selector-select {
  border-color: var(--danger);
}

/* form custom select disabled state styling (disabled state) */
#region-selector-group.form-group.is-disabled >>> select.region-selector-select,
#region-selector-group.form-group:disabled >>> select.region-selector-select {
  color: var(--ghost-white);
}

/* label styling */
#region-selector-group.form-group >>> legend.region-selector-label {
  position: absolute;
  color: var(--prime-gray);
  font-size: 1rem;
  /* width: auto; */
  width: calc(100% - 35px);
  top: 13px;
  left: 13px;
  z-index: 1;
  white-space: nowrap;
  word-wrap: normal;
  text-overflow: clip;
  user-select: none;
  pointer-events: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  transition: top 100ms linear 0s, left 100ms linear 0s, color 100ms linear 0s,
    font-size 300ms cubic-bezier(0.2, 0, 0, 1) 0s,
    opacity 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
}

/* form label focused styling (focus state) */
#region-selector-group.form-group.is-focus >>> legend.region-selector-label {
  font-size: 0.8rem;
  color: var(--info);
  width: auto;
  top: -9px;
  left: 10px;
  opacity: 1;
  z-index: 2;
}

/* form label focused error state styling (focus/valid state) */
#region-selector-group.form-group.is-focus.is-valid
  >>> legend.region-selector-label {
  color: var(--success);
}

/* form label focused error state styling (focus/invalid state) */
#region-selector-group.form-group.is-focus.is-invalid
  >>> legend.region-selector-label {
  color: var(--danger);
}

/* form label disabled state styling (disabled state) */
#region-selector-group.form-group:disabled >>> legend.region-selector-label,
#region-selector-group.form-group.is-disabled >>> legend.region-selector-label {
  color: var(--ghost-white);
}

/* label before psuedo element (default state) */
#region-selector-group.form-group >>> legend.region-selector-label:before {
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--white);
  z-index: -1;
  pointer-events: none;
  transition: width 100ms linear 0s, height 100ms linear 0s;
}

/* form label background focused styling (focus state) */
#region-selector-group.form-group.is-focus
  >>> legend.region-selector-label::before {
  width: 100%;
  height: 100%;
}

/* form label background disabled focused styling (disabled/focus state) */
#region-selector-group.form-group:disabled.is-focus
  >>> legend.region-selector-label::before,
#region-selector-group.form-group.is-disabled.is-focus
  >>> legend.region-selector-label::before {
  background-color: var(--white);
  height: 3px;
  top: 8px;
}
</style>
